import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../components/fr';
import ContactFormFr from '../components/contact-us/ContactFormFr';
import SEO from '../components/seo';

const ContactUs = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO
      title="Nous contacter - Furlan Snowboards"
      description="Contactez notre équipe"
      pathname="/fr/contact-us/"
      keywords={['snowboard', 'boards', 'polyvalence']}
    />
    <div className="contactPage">
      <header className="masthead">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 my-auto">
              <div className="header-content mx-auto">
                <h1 className="mb-3">Nous contacter</h1>
                <p className="lead mb-3">Réponse en 2-3 jours ouvrés</p>
                <ContactFormFr />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  </Layout>
);

export default ContactUs;
